import { useState } from "react";
import StandardButton from "./Buttons/StandardButton";

const navItems = [
  { id: "top-today", label: "Top today", isDefault: true },
  { id: "top-this-week", label: "Top this week" },
  { id: "top-30", label: "Top (30 days)" },
  { id: "top-all", label: "Top (All Time)" }
];

export default function GameFilterBar() {
  const [activeTab, setActiveTab] = useState("top-today");

  return (
    <nav className="w-full mt-1">
      <div className="flex items-center justify-between h-16 pr-9">
        <h2 className="font-bold text-2xl pb-2 text-custom-black">Top Endorsed Games</h2>
        <div className="flex-1 flex items-center overflow-x-auto no-scrollbar px-5">
          <div className="flex">
            {navItems.map((item) => (
              <button
                key={item.id}
                onClick={() => setActiveTab(item.id)}
                className={`
                    px-3 text-base font-medium whitespace-nowrap transition-colors
                    ${
                      activeTab === item.id
                        ? "text-gray-900"
                        : "text-gray-400 hover:text-gray-700"
                    }
                  `}
              >
                {item.label}
              </button>
            ))}
          </div>
        </div>
        <div className="flex items-center">
          <StandardButton label="see all" py="py-1" />
        </div>
      </div>
    </nav>
  );
}
