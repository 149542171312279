import React from "react";
import GameCard from "./GameCard";
import GameMainCard from "./GameMainCard"
import GameFilterBar from "./GameFilterBar";
import GameNavigationBar from "./GameNavigationBar";

function GameList() {
   return (
      <div className="flex-1 m-7 max-w-[1396px]">

         <GameFilterBar />
         <div className="flex gap-10 overflow-x-auto scrollbar-hide">
            <GameMainCard
               title="Pirate Kings"
               subtitle="clicker game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/piratekingsBig.png"
               gameUrl="https://t.me/TONPirateKings_bot"
            />
            <GameMainCard
               title="EcoDolphin"
               subtitle="Survial game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/ecodolphinBig.png"
               gameUrl="https://tonpoker.fun/games/dwarf-game/web-mobile/"
            />
            <GameMainCard
               title="Baby Gods"
               subtitle="clicker game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/babygodsBig.png"
               gameUrl="https://piratekings.fun/games/babygods/"
            />
            <GameMainCard
               title="Ton Poker"
               subtitle="poker game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/tonpokerBig.png"
               gameUrl="https://tonpoker.fun/games/poker_game/web-mobile/"
            />
            <GameMainCard
               title="Dwarven Treasure"
               subtitle="action game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/dwarvenTreasureBig.png"
               gameUrl="https://tonpoker.fun/games/dwarf-game/web-mobile/"
            />
         </div>

         <GameNavigationBar />
         <div className="flex flex-wrap gap-x-6 gap-y-6">
            <GameCard
               title="Ton Poker"
               subtitle="poker game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/tonpokerSmall.png"
               gameUrl="https://tonpoker.fun/games/poker_game/web-mobile/"
            />
            <GameCard
               title="Pirate Kings"
               subtitle="clicker game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/piratekingsSmall.png"
               gameUrl="https://t.me/TONPirateKings_bot"
            />
            <GameCard
               title="Baby Gods"
               subtitle="clicker game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/babygodsSmall.png"
               gameUrl="https://piratekings.fun/games/babygods/"
            />
            <GameCard
               title="EcoDolphin"
               subtitle="action game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/ecodolphinSmall.png"
               gameUrl="https://play.snaketon.com/games/eco-dolphin/"
            />
            <GameCard
               title="Dwarven Treasure"
               subtitle="action game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/dwarvenTreasureSmall.png"
               gameUrl="https://tonpoker.fun/games/dwarf-game/web-mobile/"
            />
            <GameCard
               title="2048"
               subtitle="2048 game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/2048Big.png"
               gameUrl="https://hikigame.com/cocos-game/2048Basic/"
            />
            <GameCard
               title="Anxiety Adventures"
               subtitle="story game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/adventurewithanxietyBig.png"
               gameUrl="https://hikigame.com/cocos-game/AdventuresWithAnxiety/"
            />
            <GameCard
               title="Clash Of Tanks"
               subtitle="action game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/clashtanksBig.png"
               gameUrl="https://hikigame.com/cocos-game/ClashOfTanks/"
            />
            <GameCard
               title="Free the Key"
               subtitle="puzzle game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/freethekeyBig.png"
               gameUrl="https://hikigame.com/cocos-game/FreeTheKey/"
            />
            <GameCard
               title="Hyper Snake"
               subtitle="hyper casual game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/hypersnakeBig.png"
               gameUrl="https://hikigame.com/cocos-game/HyperSnake/"
            />
            <GameCard
               title="One More Dash"
               subtitle="hyper casual game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/onemoredashBig.png"
               gameUrl="https://hikigame.com/cocos-game/OneMoreDash/"
            />
            <GameCard
               title="Water Sort"
               subtitle="puzzle game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/watersortBig.png"
               gameUrl="https://hikigame.com/cocos-game/WaterSort/"
            />
            <GameCard
               title="Tricky Spin"
               subtitle="puzzle game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/trickyspinBig.png"
               gameUrl="https://hikigame.com/cocos-game/TrickySpin/"
            />
            <GameCard
               title="Zuma"
               subtitle="puzzle game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/zumaBig.png"
               gameUrl="https://hikigame.com/cocos-game/Zuma/"
            />
            <GameCard
               title="Target Shoot"
               subtitle="shooting game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/targetshootBig.png"
               gameUrl="https://hikigame.com/cocos-game/TargetShoot/"
            />
            <GameCard
               title="Sushi Party"
               subtitle="snake game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/sushipartyBig.png"
               gameUrl="https://hikigame.com/cocos-game/SushiParty/"
            />
            <GameCard
               title="Terrapy"
               subtitle="puzzle game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/terrapyBig.png"
               gameUrl="https://hikigame.com/cocos-game/Terrapy/"
            />
            <GameCard
               title="Slot Fruit"
               subtitle="slot game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/slotfruitBig.png"
               gameUrl="https://hikigame.com/cocos-game/slot/"
            />
            <GameCard
               title="Save the Doge"
               subtitle="puzzle game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/savethedoge.png"
               gameUrl="https://hikigame.com/cocos-game/SaveTheDoge/"
            />
            <GameCard
               title="MineSweeper"
               subtitle="puzzle game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/minesweeperBig.png"
               gameUrl="https://hikigame.com/cocos-game/MineSweeper/"
            />
            <GameCard
               title="Farming"
               subtitle="puzzle game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/farmingBig.png"
               gameUrl="https://hikigame.com/cocos-game/Farming/"
            />
            <GameCard
               title="Flappy Bird"
               subtitle="puzzle game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/flappybirdBig.png"
               gameUrl="https://hikigame.com/cocos-game/FlappyBird/"
            />
            <GameCard
               title="Match Three"
               subtitle="puzzle game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/matchthreeBig.png"
               gameUrl="https://hikigame.com/cocos-game/MatchThree/"
            />
            <GameCard
               title="Ponng Game"
               subtitle="puzzle game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/pongBig.png"
               gameUrl="https://hikigame.com/cocos-game/Pong/"
            />
            <GameCard
               title="Vampire clicker"
               subtitle="clicker game"
               username="ev_kz"
               score={Math.round(Math.random() * 1000) + 1000}
               imageUrl="/img/card/featuredGameCards/vampireclickerBig.png"
               gameUrl="https://hikigame.com/cocos-game/VamprieClicker/"
            />
         </div>

      </div>
   );
}

export default GameList;

