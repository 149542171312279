import React, { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../components/Header/Header";
import StandardButton from "../components/Buttons/StandardButton";

const GamePreview = () => {
  const location = useLocation();
  const gameUrl = location.state || {};
  const navigate = useNavigate();
  const iframeRef = useRef(null);

  useEffect(() => {
    const focusIframe = () => {
      setTimeout(() => {
        if (iframeRef.current) {
          iframeRef.current.focus();
        }
      }, 1000);
    };
    focusIframe();
    window.addEventListener("focus", focusIframe);
    return () => {
      window.removeEventListener("focus", focusIframe);
    };
  }, []);

  const handleEditGamePrompt = () => {
    navigate("/generate", {
      state: {
        keepContent: true,
      },
    });
  };

  const handleRegenerate = () => {
    navigate("/generate", {
      state: {
        keepContent: false,
      },
    });
  };

  return (
    <div className="min-h-screen relative bg-gray-100">
      <Header status="preview" />
      <main className="container mx-auto p-12 max-w-7xl flex flex-col items-center">
        <div className="p-4 w-full max-w-6xl flex flex-col gap-8">
          <div className="w-full flex justify-between">
            <h2 className="text-2xl font-bold text-custom-black">
              Game Preview
            </h2>
            <div className="flex gap-4">
              <StandardButton
                label="Edit Prompt"
                onClick={handleEditGamePrompt}
              />
              <StandardButton
                label="Regenerate"
                onClick={handleRegenerate}
                icon={true}
              />
            </div>
          </div>
          <div className="w-full bg-white min-h-[597px] p-4 flex">
            <iframe
              ref={iframeRef}
              src={gameUrl.gameUrl}
              style={{ width: "100%", height: "", border: "none" }}
              title="Embedded Site"
              allow="autoplay; fullscreen; keyboard-map"
              tabIndex={1}
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default GamePreview;
