// src/components/ProfileMenu.js
import React, { useState } from "react";
import {
  Dot,
  Crown,
  Wallet,
  Box,
  Star,
  Settings,
  DoorOpen,
} from "lucide-react";

function ProfileMenu() {
  const [open, setOpen] = useState(false);
  const menuItems = [
    { icon: Crown, label: "pedja" },
    { icon: Wallet, label: "wallet" },
    { icon: Dot, label: "" },
    { icon: Box, label: "my games" },
    { icon: Star, label: "favorites" },
    { icon: Dot, label: "" },
    { icon: Settings, label: "settings" },
    { icon: Dot, label: "" },
    { icon: DoorOpen, label: "sign out" },
  ];

  return (
    <div className="relative z-10">
      <button
        onClick={() => setOpen(!open)}
        className="flex items-center space-x-2"
      >
        <img
          src="/avatar/default.svg"
          alt="Avatar"
          className="w-10 h-10 rounded-full p-[1.25px] border-2 border-gray-800"
        />
      </button>
      {open && (
        <div
          className="absolute right-0 mt-2 w-[187px] h-[280px] bg-custom-black backdrop-blur-lg rounded-[20px] shadow-xl 
                    border border-gray-800/50 overflow-hidden"
        >
          <ul className="py-2">
            {menuItems.map((item, index) => (
              <li key={item.label} className="flex justify-center">
                {item.icon === Dot ? (
                  <div
                    className="w-10/12 px-4 flex items-center gap-2 text-gray-300  group rounded-full"
                  >
                    <item.icon
                      className="mt-[-5px] mb-[-5px] w-5 h-5 stroke-[2.5px] group-hover:stroke-2 
                     transition-all duration-200"
                    />
                  </div>
                ) : (
                  <>
                    <button
                      className="w-10/12 px-4 py-[5px] my-[3px] flex items-center gap-3 text-gray-300 hover:text-white 
                       hover:bg-white/5 transition-colors duration-200 group rounded-full border border-custom-black hover:border hover:border-gray-500"
                      onClick={item.onClick}
                    >
                      <item.icon
                        className="w-5 h-5 stroke-[1.5px] group-hover:stroke-2 
                          transition-all duration-200"
                      />
                      <span className="text-[15px] font-medium leading-none">
                        {item.label}
                      </span>
                    </button>
                    {index === 0 && (
                      <div className="my-1 border-b border-gray-700/50" />
                    )}
                    {index === 4 && (
                      <div className="my-1 border-b border-gray-700/50" />
                    )}
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default ProfileMenu;
