import React from "react";
import {
  Crown,
  Play,
  MoreHorizontal,
  CircleArrowUp,
  CirclePlay,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

const GameCard = ({ title, subtitle, username, score, imageUrl, gameUrl }) => {
  const navigate = useNavigate();

  return (
    <div className="border-2 border-gray-300 bg-white rounded-[11px] shadow-xl hover:shadow-2xl transition-shadow duration-300 overflow-hidden w-[324px] h-[125px]">
      <div className="flex flex-col sm:flex-row">
        <div className="w-[120px] h-full relative">
          <img src={imageUrl} alt={title} className="w-full object-cover" />
          <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" />
        </div>

        <div className="w-full sm:w-2/3 px-4 py-3">
          <div className="flex justify-between items-start">
            <div>
              <h2 class="text-base font-bold text-gray-900 tracking-tight max-w-[130px] truncate overflow-hidden whitespace-nowrap">
                {title}
              </h2>
              <p className="text-xs pb-1">{subtitle}</p>
              <div className="flex items-center gap-2">
                <Crown className="h-5 w-5 text-gray-900" />
                <span className="text-sm text-gray-900 font-medium">
                  @{username}
                </span>
              </div>
            </div>

            <button className="text-gray-400 hover:text-gray-600 transition-colors p-1 hover:bg-gray-100 rounded-full border border-2">
              <MoreHorizontal className="h-5 w-8 text-gray-800" />
            </button>
          </div>

          <div className="flex justify-between items-center mt-1">
            <div className="flex items-center gap-1">
              <CircleArrowUp className="text-gray-900 bg-gray-200 rounded-full" />
              <span className="text-sm font-bold text-gray-900">{score}</span>
            </div>

            <button
              onClick={() => {
                navigate("/preview", {
                  state: { gameUrl },
                });
              }}
              className="flex items-center px-2 py-1 text-gray-800 rounded-full font-medium transition-all duration-300 hover:scale-105 hover:shadow-md custom-border"
            >
              <CirclePlay className="text-gray-900 bg-gray-200 rounded-full"/>
              <span className="ml-1 text-lg font-medium">play</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameCard;
