import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { usePromptStore } from "../store/promptStore";
import Header from "../components/Header/Header";
import GradientText from "../components/GradientText";
import BasicButton from "../components/Buttons/BasicButton";
import ThemeTag from "../components/ThemeTag";
import ProgressBar from "../components/ProgressBar";
import HeadButton from "../components/Buttons/HeadButton";

const GameGenerate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const keepContent = location.state || {};

  const { content, setContent } = usePromptStore();
  const [progress, setProgress] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [newGame, setNewGame] = useState({
    url: "",
    gameId: "",
  });
  const [activeTheme, setActiveTheme] = useState("");

  useEffect(() => {
    if(!keepContent.keepContent) {
      setContent('');
      return;
    }
   
  }, []);

  useEffect(() => {
    if (!isGenerating) return;
    const timer = setInterval(() => {
      setProgress((prev) => (prev <= 100 ? prev + 0.25 : 100));
    }, 50);
    return () => clearInterval(timer);
  }, [isGenerating]);

  const handleGenerateGame = async (e) => {
    setNewGame({ url: "", gameId: "" });
    if (isGenerating) {
      toast.warning(
        "Cannot generate a new game while one is already being generated"
      );
      return;
    }
    if (!content || content === "") {
      toast.warning("Input the prompt");
      return;
    }

    setIsGenerating(true);
    const generateGameUrl = "https://moonrun.fun/generate_game";

    try {
      const response = await axios.post(
        generateGameUrl,
        {
          prompt: content,
          assetClass: activeTheme,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.data.url || !response.data.gameId) {
        return;
      }

      setNewGame({
        url: response.data.url,
        gameId: response.data.gameId,
      });
      toast.success("Generated a new Game");
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      toast.error(" Error connecting to the generator server");
    } finally {
      setIsGenerating(false);
      setProgress(0);
    }
  };

  const openGeneratedGame = () => {
    navigate("/preview", {
      state: {
        gameUrl: "https://legacy.moonrun.fun/" + newGame.url,
        gameId: newGame.gameId,
      },
    });
  };

  const themes = [
    { id: "politics", label: "politics", isLocked: true },
    { id: "flappy_bird", label: "flappy bird" },
    { id: "clicker_game", label: "clicker game" },
    { id: "pirates", label: "pirates", isLocked: true },
    { id: "underwater", label: "underwater", isLocked: true },
    { id: "plants", label: "plants", isLocked: true },
    { id: "adventure", label: "adventure", isLocked: true },
    { id: "elictions", label: "elictions" },
    { id: "fantasy", label: "fantasy", isLocked: true },
    { id: "syfy", label: "syfy", isLocked: true },
    { id: "adventure_10", label: "adventure", isLocked: true },
    { id: "fantasy_11", label: "fantasy", isLocked: true },
    { id: "underwater_12", label: "underwater", isLocked: true },
    { id: "plants_13", label: "plants", isLocked: true },
    { id: "adventure_14", label: "adventure", isLocked: true },
  ];

  return (
    <div className="min-h-screen relative bg-gray-100">
      <Header blank={true} />
      <div className="w-full bg-white">
        <main className="container mx-auto py-8 flex">
          <div className="w-full py-6 relative">
            <GradientText text="YOUR GAME POSSIBLE" />
            <div className="w-full flex justify-center">
              <div className="w-[690px] flex flex-col items-center gap-8">
                <div className="w-full max-w-3xl">
                  <div className="relative">
                    <div className="bg-white rounded-[24px] shadow-sm border border-gray-300 pb-4">
                      <textarea
                        value={content}
                        onChange={(e) => {
                          setContent(e.target.value);
                        }}
                        placeholder="create a candy crash clone"
                        className="w-full h-[204px] min-h-[120px] p-6 text-gray-600 placeholder-gray-400 text-lg resize-none outline-none rounded-3xl"
                      />

                      <div className="absolute bottom-1 left-0 w-full flex justify-center">
                        <button
                          type="submit"
                          className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                        >
                          <img src="/icon/plus.png" alt="plus" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <BasicButton
                  text="Generate"
                  icon="CornerDownRight"
                  action={handleGenerateGame}
                />
                {isGenerating ? <ProgressBar progress={progress} /> : ""}
                {newGame.url === "" ? (
                  ""
                ) : (
                  <HeadButton
                    width="w-[250px]"
                    height="h-[33px]"
                    title="open generated game"
                    action={openGeneratedGame}
                  />
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className="w-screen absolute inset-x-0 h-[2px] bg-rainbow"></div>
      <main className="mx-auto bg-gray-100">
        <div className="w-full p-12 flex justify-center">
          <div className="text-center max-w-6xl">
            <p className="text-gray-400 mb-6">or pick some themes from below</p>
            <div className="flex flex-wrap gap-4 justify-center">
              {themes.map((theme) => (
                <ThemeTag
                  key={theme.id}
                  label={theme.label}
                  isActive={activeTheme === theme.id}
                  isLocked={theme.isLocked}
                  onClick={() => !theme.isLocked && setActiveTheme(theme.id)}
                />
              ))}
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
};

export default GameGenerate;
