import React from "react";
import { Star, Crown, CirclePlay } from "lucide-react";
import { RainbowBorder } from "./RainbowBorder";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

export function SponsoredActivityItem({ title, subtitle, username, gameUrl }) {
   const navigate = useNavigate();

   return (
      <div className="w-[327px]">
         <RainbowBorder>
            <div className="w-full h-full px-3 py-2 bg-white rounded-xl">
               <div className="flex items-center justify-between">
                  <div>
                     <div className="flex items-center gap-1">
                        <Star className="w-3 h-3 text-gray-400" />
                        <span className="text-xs text-gray-400 font-base">
                           sponsored
                        </span>
                        <Star className="w-3 h-3 text-gray-400" />
                     </div>
                     <h3 className="text-base font-bold text-gray-800 leading-4">
                        {title}
                     </h3>
                     <p className="text-sm text-gray-500 leading-4">{subtitle}</p>
                     <div className="flex items-center gap-2 mt-1">
                        <Crown className="h-4 w-4 text-gray-500 animate-pulse" />
                        <span className="text-sm text-gray-700 font-base">
                           @{username}
                        </span>
                     </div>
                  </div>

                  <div className="relative">
                     <div
                        onClick={() => {
                           navigate("/preview", {
                             state: { gameUrl }
                           });
                         }}
                        className="w-12 h-10 rounded-full bg-rainbow p-[2px] cursor-pointer hover:opacity-90 transition-opacity"
                     >
                        <div className="w-full h-full rounded-full bg-white flex items-center justify-center">
                           <CirclePlay className="text-gray-900 bg-gray-200 rounded-full"/>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </RainbowBorder>
      </div>
   );
}


SponsoredActivityItem.propTypes = {
   title: PropTypes.string.isRequired,
   subtitle: PropTypes.string.isRequired,
   username: PropTypes.string.isRequired,
};
