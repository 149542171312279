import React from "react";
import PropTypes from "prop-types";
import {
  MoreHorizontal,
  Crown,
  CirclePlay,
  CircleArrowUp,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

const GameMainCard = ({
  title,
  subtitle,
  username,
  score,
  imageUrl,
  gameUrl,
}) => {
  const navigate = useNavigate();

  return (
    <div className="w-[241px] h-[379px] relative rounded-[12px] overflow-hidden shadow-lg border-2 border-gray-300">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundPosition: "center",
        }}
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent" />

      <div className="relative h-full flex flex-col justify-between p-5">
        <div className="flex justify-end">
          <button className="w-10 h-7 bg-black/30 hover:bg-black/30 transition-colors flex items-center justify-center rounded-full hover:bg-black/20 transition-colors border border-2 border-white">
            <MoreHorizontal className="w-5 h-5 text-white" strokeWidth={2.5} />
          </button>
        </div>

        <div className="space-y-3">
          <div className="space-y-1">
            <h2 className="text-[22px] font-bold text-white leading-tight">
              {title}
            </h2>
            <p className="text-sm text-white/60 font-medium">{subtitle}</p>
          </div>

          <div className="flex items-center justify-between py-1">
            <div className="flex items-center gap-2">
              <Crown className="w-4 h-4 text-gray-400" />
              <span className="text-sm text-white/90 font-medium">
                @{username}
              </span>
            </div>
          </div>

          <div className="flex justify-between">
            <button
              onClick={() => {
                navigate("/preview", {
                  state: { gameUrl },
                });
              }}
              className="flex items-center px-3 py-2 text-gray-800 rounded-full font-medium transition-all duration-300 hover:scale-105 hover:shadow-md custom-border bg-white"
            >
              <CirclePlay className="text-gray-900 bg-gray-200 rounded-full" />
              <span className="ml-1 text-lg font-medium">play</span>
            </button>

            <div className="flex items-center gap-1">
              <CircleArrowUp className="text-white" />
              <span className="text-sm font-bold text-white">{score}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

GameMainCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

export default GameMainCard;
