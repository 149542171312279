import React from "react";
import { CornerDownRight } from "lucide-react";

const BasicButton = ({
  text = "BasicButton",
  icon = true,
  action = () => {
    return;
  },
}) => {
  return (
    <div className="flex items-center space-x-4 w-full">
      <button className="w-full" onClick={action}>
        <div className="w-full rounded-full bg-rainbow p-[2px] transition-transform duration-200 hover:scale-105">
          <div className="w-full h-full rounded-full bg-white flex items-center justify-center px-4 py-4 text-xl font-medium gap-2 hover:bg-gray-100 hover:text-custom-black">
            <CornerDownRight className="text-custom-black" />
            {text}
          </div>
        </div>
      </button>
    </div>
  );
};

export default BasicButton;
