import React from 'react';
import { Search, Box } from 'lucide-react';

export default function SearchBar({status, text}) {
  return (
    <div className="relative w-[350px]">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        {status==="preview"?(
          <Box className="h-5 w-5 text-custom-black" />
        ):(
          <Search className="h-5 w-5 text-gray-400" />
        )}
      </div>
      <input
        type="text"
        className="block w-full pl-10 pr-3 py-2.5 border border-gray-200 rounded-full leading-5 
                 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500/20 
                 transition-all duration-200 ease-in-out text-lg"
        placeholder="find a game"
        value={text}
      />
    </div>
  );
}