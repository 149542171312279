import React from "react";

const GradientText = ({text}) => {
    return (
        <h2 className="w-full text-[115px] text-white gradient-text text-center tracking-tight p-0 m-0">
            {text}
        </h2>
    )
};

export default GradientText;