import React from "react";
import ActivityFeed from "../components/ActivityFeed/ActivityFeed";
import GameList from "../components/GameList";
import Header from "../components/Header/Header";

function Home() {
  return (
    <div className="bg-gray-200 min-h-screen">
      <Header />
      <main className="px-6 py-9 flex justify-center">
        <ActivityFeed />
        <GameList />
      </main>
    </div>
  );
}

export default Home;