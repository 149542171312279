import React from "react";

const ThemeTag = ({
  label = "ThemeTag",
  isActive = false,
  isLocked = false,
  onClick = () => {
    return;
  },
}) => {
  return (
    <button
      onClick={onClick}
      className={`
      px-6 py-2 rounded-full text-gray-800 mx-1 cursor-pointer border-2 border-gray-300
      transition-all duration-200 ease-in-out
      ${
        isActive
          ? "bg-white shadow-lg shadow-indigo-200 border-gray-600 hover:bg-gray-300"
          : "bg-gray-50 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
      }
      ${isLocked ? "cursor-not-allowed opacity-75 bg-gray-300" : "cursor-pointer bg-white"}
      group flex items-center gap-2
    `}
    >
      <span className="flex items-center gap-1">
        {isActive && <img src="/icon/lock.png" alt="lock" />}
        {label}
      </span>
    </button>
  );
};

export default ThemeTag;
