import React from "react";
import { Play } from "lucide-react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { CircleArrowUp, CirclePlay } from "lucide-react";

export function ActivityItem({ title, subtitle, endorsements, gameUrl }) {
  const navigate = useNavigate();

  return (
    <div className="w-[327px] h-[74px] bg-white rounded-xl px-3 py-2 shadow-[0_2px_8px_rgba(0,0,0,0.04)] border-2">
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-base font-bold text-gray-800 leading-4">
            {title}
          </h3>
          <p className="text-sm text-gray-500 leading-4">{subtitle}</p>
          <div className="flex items-center mt-1">
            <CircleArrowUp className="text-gray-900 bg-gray-200 rounded-full text-sm" />
            <span className="text-sm text-gray-800 font-medium ml-1">
              {endorsements} users endorsed
            </span>
          </div>
        </div>
        <div className="relative">
          <div
            onClick={() => {
              navigate("/preview", {
                state: { gameUrl }
              });
            }}
            className="w-12 h-10 rounded-full bg-rainbow p-[2px] cursor-pointer hover:opacity-90 transition-opacity"
          >
            <div className="w-full h-full rounded-full bg-white flex items-center justify-center">
            <CirclePlay className="text-gray-900 bg-gray-200 rounded-full"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ActivityItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  endorsements: PropTypes.number.isRequired,
};
