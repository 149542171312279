import React from 'react';

export function RainbowBorder({ children }) {
  return (
    <div className="relative p-[4px]">
      <div 
        className="absolute inset-0 rounded-2xl"
        style={{
          background: 'linear-gradient(90deg, #FF5B84 0%, #FF9B57 25%, #FFE458 50%, #35FFCA 75%, #6D5BFF 100%)',
          maskImage: 'radial-gradient(circle at 4px 4px, black 3.25px, transparent 1.25px)',
          WebkitMaskImage: 'radial-gradient(circle at 4px 4px, black 3.25px, transparent 1.25px)',
          maskSize: '10px 8px',
          WebkitMaskSize: '8px 8px',
        }}
      />
      <div className="bg-white rounded-xl relative">
        {children}
      </div>
    </div>
  );
}
