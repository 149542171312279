import React from "react";

const StandardButton = ({
  label = "StandardButton",
  icon = false,
  onClick = () => {
    return;
  },
  py = "py-2"
}) => {
  return (
    <button onClick={onClick}>
      <div className={`w-full h-full rounded-full bg-white flex items-center justify-center px-5 ${py} text-lg font-medium gap-2 hover:bg-gray-50 text-custom-black`}>
        {label}
        {icon && <img src="/icon/plus.png" alt="plus" />}
      </div>
    </button>
  );
};

export default StandardButton;
