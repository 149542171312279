import React from "react";

const ProgressBar = ({
  progress,
  height = "h-1",
  showPercentage = true,
  animate = true,
}) => {
  const clampedProgress = Math.min(Math.max(progress, 0), 100);

  const textPosition = clampedProgress / 2;

  return (
    <div className="w-full">
      <div className="relative">
        <div className="w-full rounded-full bg-rainbow p-[2px]">
        <div className="w-full h-full rounded-full bg-white p-[1.5px]">
          <div
            className={`w-full ${height} bg-white rounded-full overflow-hidden`}
          >
            <div
              className={`${
                animate ? "transition-all duration-1000 ease-out" : ""
              } 
              h-full bg-rainbow rounded-full relative`}
              style={{ width: `${clampedProgress}%` }}
            >
              <div className="absolute inset-0 bg-gradient-to-r from-white/20 to-transparent" />
            </div>
          </div>
        </div>
        </div>
        {showPercentage && (
          <div
            className={`absolute -bottom-6 transform -translate-x-1/2 
              ${animate ? "transition-all duration-1000 ease-out" : ""}
              text-sm font-semibold`}
            style={{
              left: `${textPosition}%`,
              color: clampedProgress > 50 ? "#4F46E5" : "#6B7280",
            }}
          >
            {Math.round(clampedProgress)}%
            <div className="absolute left-1/2 bottom-full -translate-x-1/2 w-px h-1 bg-current" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;