import React from 'react';
import { ArrowLeft, Filter } from 'lucide-react';
import { ActivityItem } from './ActivityItem.jsx';
import { SponsoredActivityItem } from './SponsoredActivityItem.jsx';

export default function ActivityFeed() {

   const getTodayString = () => {
      const today = new Date();
      const day = today.getDate();
      const month = today.toLocaleString('en-US', { month: 'long' });
      return `Today, ${day} ${month}`;
    };
    
   return (
      <div className="w-[355px] bg-white rounded-xl shadow-lg border-2 border-gray-300">
         {/* Header */}
         <div className="flex items-center justify-between p-4 border-b-2 border-gray-300">
            <div className="flex items-center gap-3">
               <button className="hover:bg-gray-50 p-1.5 rounded-full transition-colors border-2">
                  <ArrowLeft className="w-5 h-5 text-gray-700" />
               </button>
               <h1 className="text-lg font-semibold text-gray-800">Activity feed</h1>
            </div>
            <button className="hover:bg-gray-50 p-1.5 rounded-full transition-colors border-2 w-12 flex justify-center">
               <Filter className="w-5 h-5 text-gray-700" />
            </button>
         </div>

         {/* Feed Content */}
         <div className="bg-[#F4EFE6] min-h-screen rounded-b-xl">
            <div className="p-4">
               <h2 className="text-gray-500 mb-3 text-base font-medium ml-4">{getTodayString()}</h2>

               <div className="space-y-3">
                  <SponsoredActivityItem
                     title="Ton Poker"
                     subtitle="poker game"
                     username="ev_kz"
                     gameUrl="https://tonpoker.fun/games/poker_game/web-mobile/"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="Pirate Kings"
                     subtitle="clicker game"
                     username="ev_kz"
                     gameUrl="https://t.me/TONPirateKings_bot"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="Baby Gods"
                     subtitle="clicker game"
                     username="ev_kz"
                     gameUrl="https://piratekings.fun/games/babygods/"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="EcoDolphin"
                     subtitle="action game"
                     username="ev_kz"
                     gameUrl="https://play.snaketon.com/games/eco-dolphin/"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="Pirate Kings"
                     subtitle="clicker game"
                     username="ev_kz"
                     gameUrl="https://t.me/TONPirateKings_bot"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="Baby Gods"
                     subtitle="clicker game"
                     username="ev_kz"
                     gameUrl="https://piratekings.fun/games/babygods/"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="EcoDolphin"
                     subtitle="action game"
                     username="ev_kz"
                     gameUrl="https://play.snaketon.com/games/eco-dolphin/"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="Pirate Kings"
                     subtitle="clicker game"
                     username="ev_kz"
                     gameUrl="https://t.me/TONPirateKings_bot"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="Baby Gods"
                     subtitle="clicker game"
                     username="ev_kz"
                     gameUrl="https://piratekings.fun/games/babygods/"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="EcoDolphin"
                     subtitle="action game"
                     username="ev_kz"
                     gameUrl="https://play.snaketon.com/games/eco-dolphin/"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="Pirate Kings"
                     subtitle="clicker game"
                     username="ev_kz"
                     gameUrl="https://t.me/TONPirateKings_bot"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="Baby Gods"
                     subtitle="clicker game"
                     username="ev_kz"
                     gameUrl="https://piratekings.fun/games/babygods/"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="EcoDolphin"
                     subtitle="action game"
                     username="ev_kz"
                     gameUrl="https://play.snaketon.com/games/eco-dolphin/"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="Pirate Kings"
                     subtitle="clicker game"
                     username="ev_kz"
                     gameUrl="https://t.me/TONPirateKings_bot"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="Baby Gods"
                     subtitle="clicker game"
                     username="ev_kz"
                     gameUrl="https://piratekings.fun/games/babygods/"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="EcoDolphin"
                     subtitle="action game"
                     username="ev_kz"
                     gameUrl="https://play.snaketon.com/games/eco-dolphin/"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="Pirate Kings"
                     subtitle="clicker game"
                     username="ev_kz"
                     gameUrl="https://t.me/TONPirateKings_bot"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="Baby Gods"
                     subtitle="clicker game"
                     username="ev_kz"
                     gameUrl="https://piratekings.fun/games/babygods/"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="EcoDolphin"
                     subtitle="action game"
                     username="ev_kz"
                     gameUrl="https://play.snaketon.com/games/eco-dolphin/"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="Pirate Kings"
                     subtitle="clicker game"
                     username="ev_kz"
                     gameUrl="https://t.me/TONPirateKings_bot"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="Baby Gods"
                     subtitle="clicker game"
                     username="ev_kz"
                     gameUrl="https://piratekings.fun/games/babygods/"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="EcoDolphin"
                     subtitle="action game"
                     username="ev_kz"
                     gameUrl="https://play.snaketon.com/games/eco-dolphin/"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="Pirate Kings"
                     subtitle="clicker game"
                     username="ev_kz"
                     gameUrl="https://t.me/TONPirateKings_bot"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="Baby Gods"
                     subtitle="clicker game"
                     username="ev_kz"
                     gameUrl="https://piratekings.fun/games/babygods/"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
                  <ActivityItem
                     title="EcoDolphin"
                     subtitle="action game"
                     username="ev_kz"
                     gameUrl="https://play.snaketon.com/games/eco-dolphin/"
                     endorsements={Math.round(Math.random() * 1000) + 1000}
                  />
               </div>
            </div>
         </div>
      </div>
   );
}